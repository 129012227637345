var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{},[_c('v-data-table',{staticClass:"px-3 datatable-board",attrs:{"headers":_vm.wrappedTableHeaders,"items":_vm.wrappedBoardItems,"items-per-page":5,"item-key":"id","show-select":false,"no-data-text":"Sin elementos actualmente"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('small',{staticClass:"font-weight-bold"},[_vm._v(" Descripción ")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.showActions)?_c('v-btn',{attrs:{"disabled":_vm.currentCubage && _vm.currentCubage.isClosed,"color":"blue darken-3","dark":false,"size":"small"},on:{"click":_vm.openModalAddBoardItems}},[_c('span',{staticClass:"text-white"},[_vm._v(" Agregar nuevo ")])]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("¿Estás seguro de eliminar el item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-3","text":""}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Eliminar")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":item.comments.length ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.openModalShowCommentsModel(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alert-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Información")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.currentCubage && _vm.currentCubage.isClosed,"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [(_vm.currentCubage && !_vm.currentCubage.isClosed && _vm.showActions)?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"type":"number","min":0,"hide-details":"","value":item.quantity},on:{"focusout":function($event){return _vm.updateBoardItem($event, item, 'quantity')}}}):_c('span',[_vm._v(_vm._s(item.quantity))])]}},{key:"item.unit_cost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.unit_cost ? ("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(item.unit_cost) ))) : ("$ " + (0))))])]}},{key:"item.total_cost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.total_cost ? ("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(item.total_cost) ))) : ("$ " + (0))))])]}},{key:"footer.prepend",fn:function(){return [_c('span',{staticClass:"ml-auto mr-5 text-datatable-board font-weight-bold"},[_vm._v(" Total: "+_vm._s(("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(_vm.getTotalMaterials) ))))+" ")])]},proxy:true}])}),_c('add-board-items',{attrs:{"modalAction":_vm.dialogAddBoardItem,"closeModalAddBoardItems":_vm.closeModalAddBoardItems},on:{"boardItemAdded":function($event){return _vm.getCubageBoardPanels({
        document_id: _vm.currentCubage.id,
      })}}}),_c('comments-from-model',{attrs:{"dialogShowCommentsModel":_vm.dialogShowCommentsModel,"closeModalShowCommentsModel":_vm.closeModalShowCommentsModel,"modelId":_vm.currentBoardItem ? _vm.currentBoardItem.id : null,"comments":_vm.comments,"modelType":"document_panel"},on:{"commentAdded":function($event){return _vm.getCubageBoardPanels({
        document_id: _vm.currentCubage.id,
      })}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }