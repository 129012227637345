<template>
  <section class="my-5 py-5">
    <v-data-table
      class="elevation-3 px-3 datatable-execution"
      v-model="materialsSelected"
      :headers="tableHeaders.filter((header) => header !== null)"
      :items="materials"
      :items-per-page="5"
      item-key="id"
      show-select
      no-data-text="Sin elementos"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <small class="font-weight-bold">
              {{ title }}
            </small>
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            v-if="showAction"
            color="red darken-3"
            :dark="false"
            size="small"
            class="mx-3"
            :disabled="!materialsSelected.length || currentCubage.isClosed"
            @click="openModalDeleteMultipleMaterials"
          >
            <span class="text-white">
              Eliminar
            </span>
          </v-btn>
          <v-btn
            v-if="showAction"
            color="blue darken-3"
            :dark="false"
            size="small"
            @click="addNewExecutionMaterialPreCreated"
            :disabled="
              isSomeCubageMaterialExecutionNotFinished ||
                (currentCubage && currentCubage.isClosed)
            "
          >
            <span class="text-white">
              Agregar
            </span>
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >¿Estás seguro de eliminar el elemento?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-3" text>Cancelar</v-btn>
                <v-btn color="red darken-1" @click="dialogDelete = false" text
                  >Eliminar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top v-if="item.name">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="item.comments.length ? 'primary' : 'secondary'"
                @click="showCommentsDocumentMaterial(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-alert-circle
                </v-icon>
              </v-btn>
            </template>
            <span>Información</span>
          </v-tooltip>
          <v-tooltip top v-if="item.name">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="currentCubage && currentCubage.isClosed"
                icon
                color="error"
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
          <v-tooltip top v-if="!item.name">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="addNewExecutionMaterial"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-check
                </v-icon>
              </v-btn>
            </template>
            <span>Agregar</span>
          </v-tooltip>
          <v-tooltip top v-if="!item.name">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="deleteExecutionMaterialPreCreated(item.id)"
                icon
                class="mx-2"
                color="red darken-3"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <v-text-field
          type="text"
          v-if="!item.name"
          class="pt-0 mt-0"
          hide-details
          v-model="form.name"
          single-line
        ></v-text-field>
        <span v-else>{{ item.name }}</span>
      </template>
      <template v-slot:item.Quantity="{ item }">
        <v-text-field
          v-if="!item.name"
          class="pt-0 mt-0"
          hide-details
          v-model="form.quantity"
          type="number"
          :min="0"
        ></v-text-field>
        <span v-else>{{ item.Quantity }}</span>
      </template>
      <template v-slot:item.Days="{ item }">
        <v-text-field
          v-if="!item.name"
          class="pt-0 mt-0"
          hide-details
          v-model="form.days"
          type="number"
          :min="0"
        ></v-text-field>
        <span v-else>{{ item.Days }}</span>
      </template>
      <template v-slot:item.Day_Month="{ item }">
        <v-text-field
          v-if="!item.name"
          class="pt-0 mt-0"
          hide-details
          v-model="form.day_month"
          type="number"
          :min="0"
        ></v-text-field>
        <span v-else>{{ item.Day_Month }}</span>
      </template>
      <template v-slot:item.Hour="{ item }">
        <v-text-field
          v-if="!item.name"
          class="pt-0 mt-0"
          hide-details
          v-model="form.hour"
          type="number"
          :min="0"
        ></v-text-field>
        <span v-else>{{ item.Hour }}</span>
      </template>
      <template v-slot:item.Staff="{ item }">
        <v-text-field
          v-if="!item.name"
          class="pt-0 mt-0"
          hide-details
          v-model="form.staff"
          type="number"
          :min="0"
        ></v-text-field>
        <span v-else>{{ item.Staff }}</span>
      </template>
      <template v-slot:item.Unit_Cost="{ item }">
        <v-text-field
          v-if="!item.name"
          class="pt-0 mt-0"
          hide-details
          v-model="form.unit_cost"
          type="number"
          :min="0"
        ></v-text-field>
        <span v-else>{{
          item.Unit_Cost
            ? `$ ${new Intl.NumberFormat("de-DE").format(
                parseInt(item.Unit_Cost)
              )}`
            : `$ ${0}`
        }}</span>
      </template>
      <template v-slot:item.total_cost="{ item }">
        <span
          >{{
            item.total_cost !== null
              ? `$ ${new Intl.NumberFormat("de-DE").format(
                  parseInt(item.total_cost)
                )}`
              : `$ ${new Intl.NumberFormat("de-DE").format(
                  parseInt(getCurrentTotalCost)
                )}`
          }}
        </span>
      </template>
      <template v-slot:footer.prepend>
        <span class="ml-auto mr-5 text-datatable-execution font-weight-bold">
          Total:
          {{
            `$ ${new Intl.NumberFormat("de-DE").format(
              parseInt(getTotalMaterials)
            )}`
          }}
        </span>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { TYPES_EXECUTION_MATERIALS } from "@/constants/cubage";
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    materials: {
      type: Array,
      default: () => [],
    },
    showAction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TYPES_EXECUTION_MATERIALS,
      debounce: null,
      debounceTime: 1000,
      dialog: false,
      dialogDelete: false,
      form: {
        name: "",
        quantity: "",
        unit_cost: "",
        days: "",
        day_month: "",
        hour: "",
        staff: "",
      },
      materialsSelected: [],
    };
  },
  methods: {
    resetForm() {
      this.form = {
        name: "",
        quantity: "",
        unit_cost: "",
        days: "",
        day_month: "",
        hour: "",
        staff: "",
      };
    },
    addNewExecutionMaterialPreCreated() {
      this.resetForm();
      this.$emit("addNewExecutionMaterialPreCreated", { type: this.type });
    },
    addNewExecutionMaterial() {
      this.$emit("addNewExecutionMaterial", {
        type: this.type,
        form: { ...this.form },
      });
    },
    deleteExecutionMaterialPreCreated(id) {
      this.$emit("deleteExecutionMaterialPreCreated", { id });
      this.resetForm();
    },
    openModalDeleteMultipleMaterials() {
      this.$emit("openModalDeleteMultipleMaterials", {
        materials: this.materialsSelected.map((item) => item.id),
      });
    },
    showCommentsDocumentMaterial(item) {
      this.$emit("openModalShowCommentsDocumentMaterial", item);
    },
    deleteItem({ id, name }) {
      this.$emit("deleteMaterial", { id, name });
    },
    async updateExecutionMaterial(value, item, field) {
      if (value) {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = setTimeout(async function() {
          let payload = {
            id: item.id,
            created_at: item.created_at,
            created_by: self.user.email,
            dispatch: field === "dispatch" ? value : item.dispatch.description,
            quantity: field === "quantity" ? value : item.quantity,
            status: true,
            cost:
              field === "cost"
                ? value
                : item.material.unit_cost
                ? item.material.unit_cost
                : 0,
          };
          await self.sendUpdateDocumentMaterial(payload);
          self.$emit("updateValuesDocumentMaterial", payload);
        }, this.debounceTime);
      }
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      currentCubage: "cubage/currentCubage",
      cubageMaterialsExecution: "cubage/cubageMaterialsExecution",
    }),
    getTotalMaterials() {
      return this.materials.reduce(
        (total, item) => (total += item.total_cost),
        0
      );
    },
    isSomeCubageMaterialExecutionNotFinished() {
      return this.cubageMaterialsExecution.some((material) => {
        return !material.name;
      });
    },
    getCurrentTotalCost() {
      switch (this.type) {
        case TYPES_EXECUTION_MATERIALS.transport:
          return this.form.quantity && this.form.unit_cost && this.form.days
            ? this.form.quantity * this.form.unit_cost * this.form.days
            : 0;
          break;
        case TYPES_EXECUTION_MATERIALS.load_transport:
          return this.form.quantity && this.form.unit_cost
            ? this.form.quantity * this.form.unit_cost
            : 0;
          break;
        case TYPES_EXECUTION_MATERIALS.machinery:
          return this.form.quantity &&
            this.form.unit_cost &&
            this.form.hour &&
            this.form.day_month
            ? this.form.quantity *
                this.form.unit_cost *
                this.form.hour *
                this.form.day_month
            : 0;
          break;
        case TYPES_EXECUTION_MATERIALS.labor:
          return this.form.unit_cost && this.form.staff && this.form.days
            ? this.form.unit_cost * this.form.staff * this.form.days
            : 0;
          break;
        case TYPES_EXECUTION_MATERIALS.lodging:
          return this.form.unit_cost && this.form.staff && this.form.days
            ? this.form.unit_cost * this.form.staff * this.form.days
            : 0;
          break;
        case TYPES_EXECUTION_MATERIALS.others:
          return this.form.quantity && this.form.unit_cost
            ? this.form.quantity * this.form.unit_cost
            : 0;
          break;
        default:
          return 0;
      }
    },
  },
  watch: {
    materials(newMaterials) {
      this.materialsSelected = [];
    },
  },
};
</script>

<style>
.datatable-execution .v-data-footer__select {
  margin-left: 10px;
}

.datatable-execution .v-data-footer {
  display: flex;
  flex-direction: row-reverse;
}

.text-datatable-execution {
  font-size: 12.5px;
}
</style>
