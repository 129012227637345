<template>
  <!-- #Comments -->
  <v-dialog
    v-model="dialogShowCommentsDocumentMaterial"
    max-width="700px"
    @keydown="closeModalShowCommentsDocumentMaterial"
    @click:outside="closeModalShowCommentsDocumentMaterial"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Comentarios</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div
            style="max-height: 300px; overflow-y: scroll"
            class="mb-2"
            v-if="comments.length && users.length"
          >
            <section v-for="(item, index) in comments" :key="index">
              <v-list-item class="py-2">
                <v-list-item-avatar>
                  <v-avatar color="#2196F3" size="56" class="white--text">
                    {{ item.created_by.toUpperCase()[0] }}
                  </v-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold"
                    >{{ getUserName(item.created_by) }}
                    <span class="mx-1"
                      >(<small>{{ item.created_by }}</small
                      >)</span
                    >
                  </v-list-item-title>
                  <p class="my-1" style="line-height: 1.8">
                    {{ item.comment }}
                  </p>
                  <span class="float-right">
                    <small>
                      {{ moment(item.created_at).format('DD/MM/YYYY HH:mm [hrs]') }}
                    </small>
                  </span>
                </v-list-item-content>
              </v-list-item>
            </section>
          </div>
          <p v-else class="text-center">
            Sin comentarios
          </p>
          <v-form>
            <v-col cols="12" md="12">
              <v-textarea v-model="form_comment.comment" solo label="Agregar comentario...">
              </v-textarea>
            </v-col>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-1"
          outlined
          text
          :disabled="loading"
          @click="closeModalShowCommentsDocumentMaterial"
        >
          <small>
            Cancelar
          </small>
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading || !form_comment.comment"
          color="blue darken-3"
          @click="updateCommentDocumentMaterial"
        >
          <small class="text-white">
            Comentar
          </small>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { authUsuarioComputed } from '@/state/helpers'
import moment from 'moment'
export default {
  props: {
    dialogShowCommentsDocumentMaterial: {
      type: Boolean,
      default: false
    },
    closeModalShowCommentsDocumentMaterial: {
      type: Function,
      default: () => {}
    },
    comments: {
      type: Array,
      default: () => []
    },
    documentMaterialId: {
      default: null
    }
  },
  data() {
    return {
      moment,
      loading: false,
      form_comment: {
        comment: '',
        last_updated: null
      }
    }
  },
  methods: {
    ...mapActions({
      addCommentToDocumentMaterial: 'cubage/addCommentToDocumentMaterial',
      getUsersFromListEmails: 'user/getUsersFromListEmails'
    }),
    getUserName(userEmail) {
      return this.users.find((user) => user.email === userEmail)?.name || userEmail
    },
    async fetchUsers(newComments) {
      let users = newComments.map((comment) => comment.created_by)
      await this.getUsersFromListEmails({
        email_list: [...new Set(users)].toString()
      })
    },
    resetFormComment() {
      this.form_comment.comment = ''
      this.form_comment.last_updated = null
    },
    async updateCommentDocumentMaterial() {
      this.loading = true
      let payload = {
        docmaterial_id: this.documentMaterialId,
        comment: this.form_comment.comment,
        created_by: this.user.email
      }
      const response = await this.addCommentToDocumentMaterial(payload)
      if (response.status === 200) {
        this.$swal({
          icon: 'success',
          title: 'Excelente',
          text: 'Comentario actualizado correctamente',
          position: 'top-end',
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true
        })
        this.closeModalShowCommentsDocumentMaterial()
        this.$emit('commentAdded')
        this.resetFormComment()
      }
      this.loading = false
    }
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      users: 'user/users'
    })
  },
  watch: {
    comments(newComments) {
      if (newComments.length) {
        this.fetchUsers(newComments)
      }
    }
  }
}
</script>

<style></style>
