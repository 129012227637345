<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      @keydown="closeModalAddBoardAttachments"
      @click:outside="closeModalAddBoardAttachments"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Añadir adjunto</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="$v.form.name.$model"
                  :error-messages="
                    $v.form.name.$invalid && submitUpload ? 'Nombre es requerido' : null
                  "
                  label="Nombre del archivo"
                  item-text="name"
                  item-value="id"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-file-input
                  v-model="$v.form.file.$model"
                  :error="$v.form.file.$invalid && submitUpload"
                  label="Archivo a adjuntar"
                ></v-file-input>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalAddBoardAttachments"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn color="blue darken-3" :loading="loading" @click="addBoardAttachments">
            <small class="text-white">
              Agregar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { authUsuarioComputed } from '@/state/helpers'
import swal2 from 'sweetalert2'

export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false
    },
    closeModalAddBoardAttachments: {
      type: Function,
      default: () => {}
    }
  },
  validations: {
    form: {
      name: { required },
      file: { required }
    }
  },
  data() {
    return {
      loading: false,
      form: {
        name: null,
        file: null
      },
      submitUpload: false
    }
  },
  methods: {
    ...mapActions({
      uploadBoardAttachmentFromDocument: 'cubage/uploadBoardAttachmentFromDocument'
    }),
    resetForm() {
      this.form = {
        name: null,
        file: null
      }
      this.submitUpload = false
    },
    async addBoardAttachments() {
      this.submitUpload = true
      if (!this.$v.form.$invalid) {
        this.loading = true
        let info = JSON.stringify({
          document_id: this.currentCubage.id,
          type: 'attachment',
          desc: this.form.name,
          user: this.user.email
        })
        const resp = await this.uploadBoardAttachmentFromDocument({
          info,
          file: this.form.file
        })
        if (resp.status === 400) {
          swal2.fire({
            icon: 'error',
            title: 'Error',
            text: 'Los formatos permitidos son: jpg, png, pdf',
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true
          })
        }
        if (resp.status == 200) {
          swal2.fire({
            icon: 'success',
            title: 'Excelente',
            text: 'Adjunto cargado correctamente',
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true
          })
          this.$emit('attachmentUploaded')
          this.closeModalAddBoardAttachments()
          this.resetForm()
        }
      }
      this.loading = false
    }
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      currentCubage: 'cubage/currentCubage'
    })
  }
}
</script>

<style></style>
