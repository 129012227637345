<template>
  <section class="">
    <v-data-table
      class="px-3 datatable-board"
      :headers="wrappedTableHeaders"
      :items="wrappedBoardItems"
      :items-per-page="5"
      item-key="id"
      :show-select="false"
      no-data-text="Sin elementos actualmente"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <small class="font-weight-bold">
              Descripción
            </small>
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            v-if="showActions"
            :disabled="currentCubage && currentCubage.isClosed"
            color="blue darken-3"
            @click="openModalAddBoardItems"
            :dark="false"
            size="small"
          >
            <span class="text-white">
              Agregar nuevo
            </span>
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >¿Estás seguro de eliminar el item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-3" text>Cancelar</v-btn>
                <v-btn color="red darken-1" @click="dialogDelete = false" text
                  >Eliminar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="item.comments.length ? 'primary' : 'secondary'"
                @click="openModalShowCommentsModel(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-alert-circle
                </v-icon>
              </v-btn>
            </template>
            <span>Información</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="currentCubage && currentCubage.isClosed"
                icon
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(item)"
              >
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.quantity="{ item }">
        <v-text-field
          type="number"
          :min="0"
          v-if="currentCubage && !currentCubage.isClosed && showActions"
          class="pt-0 mt-0"
          hide-details
          :value="item.quantity"
          @focusout="updateBoardItem($event, item, 'quantity')"
        ></v-text-field>
        <span v-else>{{ item.quantity }}</span>
      </template>
      <template v-slot:item.unit_cost="{ item }">
        <span>{{
          item.unit_cost
            ? `$ ${new Intl.NumberFormat("de-DE").format(
                parseInt(item.unit_cost)
              )}`
            : `$ ${0}`
        }}</span>
      </template>
      <template v-slot:item.total_cost="{ item }">
        <span>{{
          item.total_cost
            ? `$ ${new Intl.NumberFormat("de-DE").format(
                parseInt(item.total_cost)
              )}`
            : `$ ${0}`
        }}</span>
      </template>
      <template v-slot:footer.prepend>
        <span class="ml-auto mr-5 text-datatable-board font-weight-bold">
          Total:
          {{
            `$ ${new Intl.NumberFormat("de-DE").format(
              parseInt(getTotalMaterials)
            )}`
          }}
        </span>
      </template>
    </v-data-table>
    <add-board-items
      :modalAction="dialogAddBoardItem"
      :closeModalAddBoardItems="closeModalAddBoardItems"
      @boardItemAdded="
        getCubageBoardPanels({
          document_id: currentCubage.id,
        })
      "
    ></add-board-items>
    <comments-from-model
      :dialogShowCommentsModel="dialogShowCommentsModel"
      :closeModalShowCommentsModel="closeModalShowCommentsModel"
      :modelId="currentBoardItem ? currentBoardItem.id : null"
      :comments="comments"
      @commentAdded="
        getCubageBoardPanels({
          document_id: currentCubage.id,
        })
      "
      modelType="document_panel"
    ></comments-from-model>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddBoardItems from "./components/AddBoardItems.vue";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { authUsuarioComputed } from "@/state/helpers";
import CommentsFromModel from "../components/CommentsFromModel.vue";
export default {
  props: {
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  components: { AddBoardItems, CommentsFromModel },
  data() {
    return {
      dialogDelete: false,
      dialogAddBoardItem: false,
      dialogShowCommentsModel: false,
      debounce: null,
      debounceTime: 700,
      tableHeaders: [
        {
          text: "Nombre",
          align: "center",
          sortable: true,
          value: "panel.name",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "quantity",
          width: "10%",
        },
        {
          text: "CU",
          align: "center",
          sortable: true,
          value: "unit_cost",
          width: "20%",
        },
        {
          text: "CT",
          align: "center",
          sortable: true,
          value: "total_cost",
          width: "20%",
        },
      ],
      currentBoardItem: null,
      comments: [],
    };
  },
  methods: {
    ...mapActions({
      getCubageBoardPanels: "cubage/getCubageBoardPanels",
      deleteCubageBoardPanel: "cubage/deleteCubageBoardPanel",
      updateCubageBoardPanel: "cubage/updateCubageBoardPanel",
      updateQuantityCubageBoardPanel: "cubage/updateQuantityCubageBoardPanel",
    }),
    addNewBoardItem(newBoardItem) {
      this.boardItems.push(newBoardItem);
    },
    openModalAddBoardItems() {
      this.dialogAddBoardItem = true;
    },
    closeModalAddBoardItems($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogAddBoardItem = false;
      }
    },
    openModalShowCommentsModel(item) {
      this.currentBoardItem = item;
      this.comments = item.comments;
      this.dialogShowCommentsModel = true;
    },
    closeModalShowCommentsModel($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogShowCommentsModel = false;
      }
    },
    async updateBoardItem(event, item) {
      let value = event.target ? event.target.value : event;
      if (value) {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = setTimeout(async function() {
          let payload = {
            doc_panel_id: item.id,
            quantity: value,
            user: self.user.email,
          };
          const { data } = await self.updateCubageBoardPanel(payload);
          self.updateQuantityCubageBoardPanel({ ...payload, newId: data.id });
        }, this.debounceTime);
      }
    },
    deleteItem({ id, panel }) {
      let self = this;
      this.$swal
        .fire({
          icon: "error",
          title: `Deseas eliminar este tablero: ${panel.name} ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const resp = await this.deleteCubageBoardPanel({
              id,
              user: self.user.email,
            });
            if (resp.status === 200) {
              self.$swal({
                icon: "success",
                title: "Excelente",
                text: "Tablero eliminado correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              self.getCubageBoardPanels({
                document_id: self.currentCubage.id,
              });
            }
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      currentCubage: "cubage/currentCubage",
      cubageBoardPanels: "cubage/cubageBoardPanels",
    }),
    wrappedBoardItems() {
      return this.cubageBoardPanels.filter((item) => item.status);
    },
    getTotalMaterials() {
      return this.wrappedBoardItems.reduce(
        (total, item) => (total += item.total_cost),
        0
      );
    },
    wrappedTableHeaders() {
      if (this.showActions) {
        return [
          ...this.tableHeaders,
          {
            text: "Acciones",
            align: "center",
            value: "actions",
            sortable: false,
          },
        ];
      }
      return this.tableHeaders;
    },
  },
};
</script>

<style>
.datatable-board .v-data-footer__select {
  margin-left: 10px;
}

.datatable-board .v-data-footer {
  display: flex;
  flex-direction: row-reverse;
}

.text-datatable-board {
  font-size: 12.5px;
}
</style>
