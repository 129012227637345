var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"my-5 py-5"},[_c('v-data-table',{staticClass:"elevation-3 px-3 datatable-execution",attrs:{"headers":_vm.tableHeaders.filter(function (header) { return header !== null; }),"items":_vm.materials,"items-per-page":5,"item-key":"id","show-select":"","no-data-text":"Sin elementos"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('small',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.showAction)?_c('v-btn',{staticClass:"mx-3",attrs:{"color":"red darken-3","dark":false,"size":"small","disabled":!_vm.materialsSelected.length || _vm.currentCubage.isClosed},on:{"click":_vm.openModalDeleteMultipleMaterials}},[_c('span',{staticClass:"text-white"},[_vm._v(" Eliminar ")])]):_vm._e(),(_vm.showAction)?_c('v-btn',{attrs:{"color":"blue darken-3","dark":false,"size":"small","disabled":_vm.isSomeCubageMaterialExecutionNotFinished ||
              (_vm.currentCubage && _vm.currentCubage.isClosed)},on:{"click":_vm.addNewExecutionMaterialPreCreated}},[_c('span',{staticClass:"text-white"},[_vm._v(" Agregar ")])]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("¿Estás seguro de eliminar el elemento?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-3","text":""}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Eliminar")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[(item.name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":item.comments.length ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.showCommentsDocumentMaterial(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alert-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Información")])]):_vm._e(),(item.name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.currentCubage && _vm.currentCubage.isClosed,"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e(),(!item.name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":_vm.addNewExecutionMaterial}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Agregar")])]):_vm._e(),(!item.name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","color":"red darken-3"},on:{"click":function($event){return _vm.deleteExecutionMaterialPreCreated(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e()],1)]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [(!item.name)?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"type":"text","hide-details":"","single-line":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.Quantity",fn:function(ref){
              var item = ref.item;
return [(!item.name)?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"hide-details":"","type":"number","min":0},model:{value:(_vm.form.quantity),callback:function ($$v) {_vm.$set(_vm.form, "quantity", $$v)},expression:"form.quantity"}}):_c('span',[_vm._v(_vm._s(item.Quantity))])]}},{key:"item.Days",fn:function(ref){
              var item = ref.item;
return [(!item.name)?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"hide-details":"","type":"number","min":0},model:{value:(_vm.form.days),callback:function ($$v) {_vm.$set(_vm.form, "days", $$v)},expression:"form.days"}}):_c('span',[_vm._v(_vm._s(item.Days))])]}},{key:"item.Day_Month",fn:function(ref){
              var item = ref.item;
return [(!item.name)?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"hide-details":"","type":"number","min":0},model:{value:(_vm.form.day_month),callback:function ($$v) {_vm.$set(_vm.form, "day_month", $$v)},expression:"form.day_month"}}):_c('span',[_vm._v(_vm._s(item.Day_Month))])]}},{key:"item.Hour",fn:function(ref){
              var item = ref.item;
return [(!item.name)?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"hide-details":"","type":"number","min":0},model:{value:(_vm.form.hour),callback:function ($$v) {_vm.$set(_vm.form, "hour", $$v)},expression:"form.hour"}}):_c('span',[_vm._v(_vm._s(item.Hour))])]}},{key:"item.Staff",fn:function(ref){
              var item = ref.item;
return [(!item.name)?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"hide-details":"","type":"number","min":0},model:{value:(_vm.form.staff),callback:function ($$v) {_vm.$set(_vm.form, "staff", $$v)},expression:"form.staff"}}):_c('span',[_vm._v(_vm._s(item.Staff))])]}},{key:"item.Unit_Cost",fn:function(ref){
              var item = ref.item;
return [(!item.name)?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"hide-details":"","type":"number","min":0},model:{value:(_vm.form.unit_cost),callback:function ($$v) {_vm.$set(_vm.form, "unit_cost", $$v)},expression:"form.unit_cost"}}):_c('span',[_vm._v(_vm._s(item.Unit_Cost ? ("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(item.Unit_Cost) ))) : ("$ " + (0))))])]}},{key:"item.total_cost",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.total_cost !== null ? ("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(item.total_cost) ))) : ("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(_vm.getCurrentTotalCost) ))))+" ")])]}},{key:"footer.prepend",fn:function(){return [_c('span',{staticClass:"ml-auto mr-5 text-datatable-execution font-weight-bold"},[_vm._v(" Total: "+_vm._s(("$ " + (new Intl.NumberFormat("de-DE").format( parseInt(_vm.getTotalMaterials) ))))+" ")])]},proxy:true}]),model:{value:(_vm.materialsSelected),callback:function ($$v) {_vm.materialsSelected=$$v},expression:"materialsSelected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }