<template>
  <section class="mt-5">
    <datatable-materials-execution
      @addNewExecutionMaterialPreCreated="setNewExecutionMaterialPreCreated"
      @addNewExecutionMaterial="addNewExecutionMaterial"
      @deleteExecutionMaterialPreCreated="deleteExecutionMaterialPreCreated"
      @deleteMaterial="openModalDeleteMaterial"
      @openModalShowCommentsDocumentMaterial="openModalShowCommentsModel"
      :title="TYPES_EXECUTION_MATERIALS.transport"
      :materials="
        getCubageMaterialsExecutionFromType(TYPES_EXECUTION_MATERIALS.transport)
      "
      :tableHeaders="[
        ...tableHeadersPersonalTransport,
        showAction ? actionsItem : null,
      ]"
      :type="TYPES_EXECUTION_MATERIALS.transport"
      :showAction="showAction"
    ></datatable-materials-execution>
    <datatable-materials-execution
      @addNewExecutionMaterialPreCreated="setNewExecutionMaterialPreCreated"
      @addNewExecutionMaterial="addNewExecutionMaterial"
      @deleteExecutionMaterialPreCreated="deleteExecutionMaterialPreCreated"
      @deleteMaterial="openModalDeleteMaterial"
      @openModalShowCommentsDocumentMaterial="openModalShowCommentsModel"
      :title="TYPES_EXECUTION_MATERIALS.load_transport"
      :materials="
        getCubageMaterialsExecutionFromType(
          TYPES_EXECUTION_MATERIALS.load_transport
        )
      "
      :tableHeaders="[
        ...tableHeadersFreightTransport,
        showAction ? actionsItem : null,
      ]"
      :type="TYPES_EXECUTION_MATERIALS.load_transport"
      :showAction="showAction"
    ></datatable-materials-execution>
    <datatable-materials-execution
      @addNewExecutionMaterialPreCreated="setNewExecutionMaterialPreCreated"
      @addNewExecutionMaterial="addNewExecutionMaterial"
      @deleteExecutionMaterialPreCreated="deleteExecutionMaterialPreCreated"
      @deleteMaterial="openModalDeleteMaterial"
      @openModalShowCommentsDocumentMaterial="openModalShowCommentsModel"
      :title="TYPES_EXECUTION_MATERIALS.machinery"
      :materials="
        getCubageMaterialsExecutionFromType(TYPES_EXECUTION_MATERIALS.machinery)
      "
      :tableHeaders="[
        ...tableHeadersMachineryAndWork,
        showAction ? actionsItem : null,
      ]"
      :type="TYPES_EXECUTION_MATERIALS.machinery"
      :showAction="showAction"
    ></datatable-materials-execution>
    <datatable-materials-execution
      @addNewExecutionMaterialPreCreated="setNewExecutionMaterialPreCreated"
      @addNewExecutionMaterial="addNewExecutionMaterial"
      @deleteExecutionMaterialPreCreated="deleteExecutionMaterialPreCreated"
      @deleteMaterial="openModalDeleteMaterial"
      @openModalShowCommentsDocumentMaterial="openModalShowCommentsModel"
      :title="TYPES_EXECUTION_MATERIALS.labor"
      :materials="
        getCubageMaterialsExecutionFromType(TYPES_EXECUTION_MATERIALS.labor)
      "
      :tableHeaders="[...tableHeadersLabor, showAction ? actionsItem : null]"
      :type="TYPES_EXECUTION_MATERIALS.labor"
      :showAction="showAction"
    ></datatable-materials-execution>
    <datatable-materials-execution
      @addNewExecutionMaterialPreCreated="setNewExecutionMaterialPreCreated"
      @addNewExecutionMaterial="addNewExecutionMaterial"
      @deleteExecutionMaterialPreCreated="deleteExecutionMaterialPreCreated"
      @deleteMaterial="openModalDeleteMaterial"
      @openModalShowCommentsDocumentMaterial="openModalShowCommentsModel"
      :title="TYPES_EXECUTION_MATERIALS.lodging"
      :materials="
        getCubageMaterialsExecutionFromType(TYPES_EXECUTION_MATERIALS.lodging)
      "
      :tableHeaders="[
        ...tableHeadersLodgingAndPerDiem,
        showAction ? actionsItem : null,
      ]"
      :type="TYPES_EXECUTION_MATERIALS.lodging"
      :showAction="showAction"
    ></datatable-materials-execution>
    <datatable-materials-execution
      @addNewExecutionMaterialPreCreated="setNewExecutionMaterialPreCreated"
      @addNewExecutionMaterial="addNewExecutionMaterial"
      @deleteExecutionMaterialPreCreated="deleteExecutionMaterialPreCreated"
      @deleteMaterial="openModalDeleteMaterial"
      @openModalShowCommentsDocumentMaterial="openModalShowCommentsModel"
      :title="TYPES_EXECUTION_MATERIALS.others"
      :materials="
        getCubageMaterialsExecutionFromType(TYPES_EXECUTION_MATERIALS.others)
      "
      :tableHeaders="[...tableHeadersOthers, showAction ? actionsItem : null]"
      :type="TYPES_EXECUTION_MATERIALS.others"
      :showAction="showAction"
    ></datatable-materials-execution>
    <comments-from-model
      :dialogShowCommentsModel="dialogShowCommentsModel"
      :closeModalShowCommentsModel="closeModalShowCommentsModel"
      :modelId="currentMaterialExecution ? currentMaterialExecution.id : null"
      :comments="comments"
      @commentAdded="
        getCubageMaterialsExecution({
          document_id: currentCubage.id,
        })
      "
      modelType="document_service"
    ></comments-from-model>
  </section>
</template>

<script>
import DatatableMaterialsExecution from "./DatatableMaterialsExecution.vue";
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import {
  TYPES_EXECUTION_MATERIALS,
  tableHeadersPersonalTransport,
  tableHeadersFreightTransport,
  tableHeadersMachineryAndWork,
  tableHeadersLabor,
  tableHeadersLodgingAndPerDiem,
  tableHeadersOthers,
} from "@/constants/cubage";
import CommentsFromModel from "../components/CommentsFromModel.vue";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
export default {
  props: {
    showAction: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DatatableMaterialsExecution,
    CommentsFromModel,
  },
  data() {
    return {
      loading: false,
      TYPES_EXECUTION_MATERIALS,
      actionsItem: {
        text: "Acciones",
        align: "center",
        value: "actions",
        sortable: false,
      },
      currentMaterialToAddId: null,
      dialogShowCommentsModel: false,
      currentMaterialExecution: null,
      comments: [],
      tableHeadersPersonalTransport,
      tableHeadersFreightTransport,
      tableHeadersMachineryAndWork,
      tableHeadersLabor,
      tableHeadersLodgingAndPerDiem,
      tableHeadersOthers,
    };
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      currentCubage: "cubage/currentCubage",
      cubageMaterialsExecution: "cubage/cubageMaterialsExecution",
    }),
  },
  methods: {
    ...mapActions({
      getCubageMaterialsExecution: "cubage/getCubageMaterialsExecution",
      addCubageMaterialExecutionPreCreated:
        "cubage/addCubageMaterialExecutionPreCreated",
      sendAddCubageMaterialExecution: "cubage/addCubageMaterialExecution",
      spliceCubageMaterialExecutionPreCreated:
        "cubage/spliceCubageMaterialExecutionPreCreated",
      deleteMultipleCubageMaterialesExecution:
        "cubage/deleteMultipleCubageMaterialesExecution",
    }),
    openModalShowCommentsModel(item) {
      this.currentMaterialExecution = item;
      this.comments = item.comments;
      this.dialogShowCommentsModel = true;
    },
    closeModalShowCommentsModel($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogShowCommentsModel = false;
      }
    },
    getCubageMaterialsExecutionFromType(type) {
      return this.cubageMaterialsExecution.filter(
        (material) => material.service_type === type
      );
    },
    setNewExecutionMaterialPreCreated({ type }) {
      this.currentMaterialToAddId = new Date().getTime();
      this.addCubageMaterialExecutionPreCreated({
        id: this.currentMaterialToAddId,
        pre_created: true,
        name: null,
        service_type: type,
        total_cost: null,
        Unit_Cost: null,
        Days: null,
        Quantity: null,
        Hour: null,
        Day_Month: null,
        Staff: null,
      });
    },
    getPayloadMaterialFromType(type, form) {
      let defaultPayload = {
        document_id: this.currentCubage.id,
        name: form.name,
        service_type: type,
        user: this.user.email,
      };
      switch (type) {
        case TYPES_EXECUTION_MATERIALS.transport:
          defaultPayload.data = {
            Unit_Cost: form.unit_cost,
            Days: form.days,
            Quantity: form.quantity,
          };
          break;
        case TYPES_EXECUTION_MATERIALS.load_transport:
          defaultPayload.data = {
            Unit_Cost: form.unit_cost,
            Quantity: form.quantity,
          };
          break;
        case TYPES_EXECUTION_MATERIALS.machinery:
          defaultPayload.data = {
            Unit_Cost: form.unit_cost,
            Quantity: form.quantity,
            Hour: form.hour,
            Day_Month: form.day_month,
          };
          break;
        case TYPES_EXECUTION_MATERIALS.labor:
          defaultPayload.data = {
            Unit_Cost: form.unit_cost,
            Staff: form.staff,
            Days: form.days,
          };
          break;
        case TYPES_EXECUTION_MATERIALS.lodging:
          defaultPayload.data = {
            Unit_Cost: form.unit_cost,
            Staff: form.staff,
            Days: form.days,
          };
          break;
        case TYPES_EXECUTION_MATERIALS.others:
          defaultPayload.data = {
            Unit_Cost: form.unit_cost,
            Quantity: form.quantity,
          };
          break;

        default:
          break;
      }
      return defaultPayload;
    },
    validatePayloadExecutionMaterial(payload) {
      return Object.values(payload).every(
        (field) => field !== null && field !== ""
      );
    },
    async addNewExecutionMaterial({ type, form }) {
      this.loading = true;
      let payload = this.getPayloadMaterialFromType(type, form);
      let validatePayload = this.validatePayloadExecutionMaterial({
        ...payload,
        ...payload.data,
      });
      if (validatePayload) {
        const resp = await this.sendAddCubageMaterialExecution({
          payload,
          oldId: this.currentMaterialToAddId,
        });
        if (resp.status === 200) {
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: "Elemento agregado correctamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 3000,
            timerProgressBar: true,
          });
          this.currentMaterialToAddId = null;
        }
      } else {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Debe llenar todos los campos",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
    deleteExecutionMaterialPreCreated({ id }) {
      this.spliceCubageMaterialExecutionPreCreated(id);
      this.currentMaterialToAddId = null;
    },
    openModalDeleteMaterial({ id, name }) {
      let self = this;
      this.$swal
        .fire({
          icon: "error",
          title: `Deseas eliminar este elemento: ${name} ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const resp = await this.deleteMultipleCubageMaterialesExecution({
              user_id: self.user.email,
              services_list: [id],
            });
            if (resp.status === 200) {
              self.$swal({
                icon: "success",
                title: "Excelente",
                text: "Elemento eliminado correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getCubageMaterialsExecution({
                document_id: self.currentCubage.id,
              });
            }
          }
        });
    },
  },
};
</script>

<style></style>
