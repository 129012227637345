<template>
  <section class="my-5 py-5">
    <v-data-table
      class="px-3"
      :headers="wrappedTableHeaders"
      :items="wrappedCubageBoardAttachments"
      :items-per-page="5"
      item-key="id"
      :show-select="false"
      no-data-text="Sin elementos actualmente"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <small class="font-weight-bold">
              Adjuntos
            </small>
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            v-if="showActions"
            color="blue darken-3"
            :disabled="currentCubage && currentCubage.isClosed"
            @click="openModalAddBoardAttachments"
            :dark="false"
            size="small"
          >
            <span class="text-white">
              Agregar nuevo
            </span>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.desc="{ item }">
        <p class="text-left mt-2">
          {{ item.desc }}
        </p>
      </template>
      <template v-slot:item.created_at="{ item }">
        <p class="text-left mt-2">
          {{ new Date(item.created_at).toLocaleDateString() }}
        </p>
      </template>
      <template v-slot:item.format="{ item }">
        <p class="text-center mt-2 mr-3">
          <v-icon small :color="formatFilesIcons[item.format].color">
            {{ formatFilesIcons[item.format].file }}
          </v-icon>
        </p>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="item.comments.length ? 'primary' : 'secondary'"
                @click="openModalShowCommentsModel(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-alert-circle
                </v-icon>
              </v-btn>
            </template>
            <span>Información</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                target="_blank"
                :href="item.download_url"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Ver archivo</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="currentCubage && currentCubage.isClosed"
                icon
                color="error"
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <add-board-attachments
      :modalAction="dialogAddBoardAttachment"
      :closeModalAddBoardAttachments="closeModalAddBoardAttachments"
      @attachmentUploaded="
        getCubageBoardAttachments({
          document_id: currentCubage.id,
        })
      "
    ></add-board-attachments>
    <comments-from-model
      :dialogShowCommentsModel="dialogShowCommentsModel"
      :closeModalShowCommentsModel="closeModalShowCommentsModel"
      :modelId="currentBoardAttachment ? currentBoardAttachment.id : null"
      :comments="comments"
      @commentAdded="
        getCubageBoardAttachments({
          document_id: currentCubage.id,
        })
      "
      modelType="document_attachment"
    ></comments-from-model>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddBoardAttachments from "./components/AddBoardAttachments.vue";
import {
  preventCloseIfNotKeyEventEsc,
  getExtensionFileFromUrl,
} from "@/helpers/common";
import { authUsuarioComputed } from "@/state/helpers";
import CommentsFromModel from "../components/CommentsFromModel.vue";
export default {
  props: {
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  components: { AddBoardAttachments, CommentsFromModel },
  data() {
    return {
      formatFilesIcons: {
        xls: { file: "mdi-file-excel-outline", color: "green" },
        pptx: { file: "mdi-file-powerpoint-outline", color: "orange" },
        doc: { file: "mdi-file-word-outline", color: "blue" },
        pdf: { file: "mdi-file-pdf-box", color: "red" },
        png: { file: "mdi-image-outline", color: "blue" },
        jpg: { file: "mdi-image-outline", color: "blue" },
      },
      dialogAddBoardAttachment: false,
      dialogShowCommentsModel: false,
      tableHeaders: [
        {
          text: "Formato",
          align: "center",
          sortable: true,
          value: "format",
          width: "20%",
        },
        {
          text: "Nombre",
          sortable: true,
          value: "desc",
          width: "60%",
        },
        {
          text: "Fecha",
          align: "center",
          sortable: true,
          value: "created_at",
          width: "10%",
        },
      ],
      currentBoardAttachment: null,
      comments: [],
    };
  },
  methods: {
    ...mapActions({
      getCubageBoardAttachments: "cubage/getCubageBoardAttachments",
      deleteAttachment: "cubage/deleteAttachment",
    }),
    openModalAddBoardAttachments() {
      this.dialogAddBoardAttachment = true;
    },
    closeModalAddBoardAttachments($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogAddBoardAttachment = false;
      }
    },
    openModalShowCommentsModel(item) {
      this.currentBoardAttachment = item;
      this.comments = item.comments;
      this.dialogShowCommentsModel = true;
    },
    closeModalShowCommentsModel($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogShowCommentsModel = false;
      }
    },
    deleteItem({ id, desc }) {
      let self = this;
      this.$swal
        .fire({
          icon: "error",
          title: `Deseas eliminar este adjunto: ${desc} ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const resp = await this.deleteAttachment({
              id: id,
              user: self.user.email,
              type: "attachment",
            });
            if (resp.status === 200) {
              this.$swal({
                icon: "success",
                title: "Excelente",
                text: "Material eliminado correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              self.getCubageBoardAttachments({
                document_id: self.currentCubage.id,
              });
            }
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      currentCubage: "cubage/currentCubage",
      cubageBoardAttachments: "cubage/cubageBoardAttachments",
    }),
    wrappedCubageBoardAttachments() {
      return this.cubageBoardAttachments
        .filter((attachment) => attachment.aws_key !== "ELIMINADO")
        .map((attachment) => {
          return {
            ...attachment,
            format: getExtensionFileFromUrl(attachment.aws_key),
            comments: [],
          };
        });
    },
    wrappedTableHeaders() {
      if (this.showActions) {
        return [
          ...this.tableHeaders,
          {
            text: "Acciones",
            align: "center",
            value: "actions",
            sortable: false,
            width: "10%",
          },
        ];
      }
      return this.tableHeaders;
    },
  },
};
</script>

<style></style>
