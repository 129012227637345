<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      @keydown="closeModalAddBoardItems"
      @click:outside="closeModalAddBoardItems"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Añadir tablero</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-col cols="12" md="12">
                <v-autocomplete
                  v-model="$v.form.board_item_id.$model"
                  :error-messages="
                    $v.form.board_item_id.$invalid && submitUpload
                      ? 'Tablero es requerido'
                      : null
                  "
                  :items="allPanels"
                  label="Tablero"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalAddBoardItems"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn color="blue darken-3" :loading="loading" @click="addBoardItem">
            <small class="text-white">
              Agregar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { authUsuarioComputed } from '@/state/helpers'

export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false
    },
    closeModalAddBoardItems: {
      type: Function,
      default: () => {}
    }
  },
  validations: {
    form: {
      board_item_id: { required }
    }
  },
  data() {
    return {
      loading: false,
      form: {
        board_item_id: null
      },
      submitUpload: false
    }
  },
  mounted() {
    this.getAllPanels()
  },
  methods: {
    ...mapActions({
      getAllPanels: 'cubage/getAllPanels',
      addCubageBoardPanelFromDocument: 'cubage/addCubageBoardPanelFromDocument'
    }),
    resetForm() {
      this.form = {
        board_item_id: null
      }
      this.submitUpload = false
    },
    async addBoardItem() {
      this.submitUpload = true
      this.loading = true
      if (!this.$v.form.$invalid) {
        let payload = {
          document_id: this.currentCubage.id,
          user: this.user.email,
          panel_id: this.form.board_item_id,
          quantity: 1
        }
        const resp = await this.addCubageBoardPanelFromDocument(payload)
        if (resp.status === 200) {
          this.$swal({
            icon: 'success',
            title: 'Excelente',
            text: 'Tablero agregado correctamente',
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            timer: 3000,
            timerProgressBar: true
          })
          this.$emit('boardItemAdded')
          this.resetForm()
          this.closeModalAddBoardItems()
        }
      }

      this.loading = false
    }
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      allPanels: 'cubage/allPanels',
      currentCubage: 'cubage/currentCubage'
    })
  }
}
</script>

<style></style>
